import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export default function LoaderPage() {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const beepSoundRef = useRef(null); // Create a reference for the audio element

    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#000000',
        color: '#ffffff',
        position: 'relative',
        overflow: 'hidden',
    };

    const textContainerStyle = {
        textAlign: 'center',
        zIndex: 10,
    };

    const titleStyle = {
        fontSize: '48px',
        fontWeight: 'bold',
        letterSpacing: '2px',
        textShadow: '0 0 10px #FFD700, 0 0 20px #FFD700',
        transition: 'color 1s ease', // Smooth transition for text color
    };

    const buttonStyle = {
        marginTop: '20px',
        padding: '10px 20px',
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#FFD700',
        backgroundColor: 'transparent',
        border: '2px solid #FFD700',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        textShadow: '0 0 5px #FFD700, 0 0 10px #FFD700',
    };

    const buttonHoverStyle = {
        backgroundColor: '#FFD700',
        color: '#000000',
    };

    const handleMouseEnter = (e) => {
        Object.assign(e.target.style, buttonHoverStyle);
    };

    const handleMouseLeave = (e) => {
        Object.assign(e.target.style, {
            backgroundColor: 'transparent',
            color: '#FFD700',
        });
    };

    const handleClick = () => {
        console.log("Button clicked!"); // Debug log
        setIsLoading(true); // Start loading animation
        beepSoundRef.current.volume = 1; // Set volume to 100%
        
        // Check if beepSoundRef is valid
        if (beepSoundRef.current) {
            beepSoundRef.current.play().catch(error => {
                console.error("Audio play failed:", error);
            }); // Play the beep sound
        } else {
            console.error("Beep sound reference is null"); // Debug log
        }

        setTimeout(() => {
            history.push('/home'); // Use history.push to navigate
        }, 2000); // Delay matches the new animation duration
    };

    return (
        <div style={containerStyle} className={isLoading ? 'fade-out' : ''}>
            <audio ref={beepSoundRef} src="/beep.mp3" /> {/* Ensure the path is correct */}
            <div style={textContainerStyle}>
                <div style={titleStyle}>
                    <span className={isLoading ? 'loading-title' : ''}>REMMICON</span>
                </div>
                <button
                    style={buttonStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                >
                    ENTER
                </button>
            </div>
            <style>{`
                body {
                    background-color: #000000; /* Ensure the body background is black */
                }
                @keyframes slideIn {
                    0% {
                        transform: translateX(100%);
                        opacity: 0;
                    }
                    100% {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
                @keyframes slideOut {
                    0% {
                        transform: translateX(0);
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(-100%);
                        opacity: 0;
                    }
                }
                .fade-out {
                    animation: slideOut 2s forwards; /* Changed duration to 2 seconds */
                    background-color: #000000; /* Ensure fade-out background is black */
                }
                .loading-title {
                    color: #000000 !important; /* Change to black when loading */
                }
            `}</style>
        </div>
    );    
}
