import React, { lazy, Suspense } from 'react'
import M from 'materialize-css'
import './App.css'
import 'material-icons/iconfont/material-icons.css'
import 'materialize-css/dist/css/materialize.min.css'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import Loader from './components/loader'
const Home = lazy(() => import('./screens/home'))

const App = function () {

  React.useEffect(function () {
    document.title = 'REMMICON'
    M.AutoInit()
    // eslint-disable-next-line
  }, [])

  return (
    <React.Fragment>
      <Suspense>
        <BrowserRouter>
          <Switch>
            <Route exact path='/' component={Loader} />
            <Route path='/home' component={Home} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </React.Fragment>)
}

export default App